<template>
  <div class="home">
    <van-tabs v-model="curTab" class="tabs" swipeable @change="handleChangeTabs">
      <van-tab v-for="item of tabData" :key="item.catid" :title="item.catname" :name="item.catid" />
    </van-tabs>
    <div class="list" v-for="(item, index) of hotelList.lists" :key="`list-${index}`">
      <div class="item__image">
        <van-swipe class="item__image-swiper" :show-indicators="false">
          <van-swipe-item v-for="(image, index) in item.hotelimage" :key="`image-${image}-${index}`">
            <van-image width="100%" height="100%" :src="image.url" lazy-load />
          </van-swipe-item>
        </van-swipe>
        <van-button class="getmore-btn" size="small" @click="handleToDetail(item.catid, item.id)">了解详情</van-button>
      </div>
      <div class="item">
        <div class="title">{{ item.title }}</div>
        <div class="item-description" v-for="subItem of item.product" :key="`list-item-${subItem.title}`">
          <div class="item__info">
            <div class="item__title">{{ subItem.title }}</div>
            <div class="item__price">
              <span class="item__price-discount price"><b>￥{{ subItem.price }}元</b>{{ subItem.unit && '/' + subItem.unit }}</span>
              <span class="item__price-origin price">￥{{ subItem.showprice }}元{{ subItem.unit && '/' + subItem.unit }}</span>
            </div>
          </div>
          <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" @click="handleToBook(item, subItem)">立即预订</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listenScrollBottom from '@/utils/listenScrollBottom'
import { getCategory, getHotelList } from '../api'
export default {
  name: 'HomePage',
  data() {
    return {
      curTab: 3,
      tabData: [],
      hotelList: {
        lists: [],
        total: 0,
        page: 1,
        pagesize: 10,
        isRequest: false,
        isEnding: false
      },
      isAddGetMoreEvents: false,
      removeGetMoreEventListener: () => {},
    }
  },
  inject: ['app'],
  methods: {
    getCategory() {
      getCategory().then(res => {
        const { data } = res
        this.tabData = [ { catid: '3', catname: '全部' }, ...data ]
      })
    },
    getHotelList(isInit = false) {
      // 切换栏目 数据初始化
      if (isInit) {
        this.hotelList = {
          lists: [],
          total: 0,
          page: 1,
          pagesize: 10,
          isRequest: false,
          isEnding: false
        }
      }
      if (this.hotelList.isRequest || this.hotelList.isEnding) return
      this.hotelList.isRequest = true
      getHotelList({ page: this.hotelList.page, pagesize: this.hotelList.pagesize, catid: this.curTab }).then(res => {
        const { content, pagetotal, page } = res.data
        console.log('res: ', content)
        this.hotelList.lists.push(...content)
        this.hotelList.page++
        // 总页数小于等于当前页，无更多数据
        if (pagetotal <= page) {
          this.hotelList.isEnding = true
        }
      }).finally(() => {
        this.hotelList.isRequest = false
      })
    },
    handleChangeTabs() {
      this.getHotelList(true)
    },
    addGetMoreEvents() {
      if (this.isAddGetMoreEvents) return
			this.removeGetMoreEventListener = listenScrollBottom(() => {
				// 触发滚动到底部事件
        this.getHotelList()
			}, 300);
			this.isAddGetMoreEvents = true;
    },
    handleToBook(hotel, product) {
      const { catid: hcatid, id: hid } = hotel
      const { catid: pcatid, id: pid } = product
      this.$router.push({ name: 'bookconfirm', query: { hcatid, hid, pcatid, pid } })
    },
    handleToDetail(catid, id) {
      this.$router.push({ name: 'detail', query: { catid, id } })
    }
  },
  mounted() {
    this.getCategory()
    this.getHotelList()
    this.addGetMoreEvents()
  },
  beforeDestroy() {
    this.removeGetMoreEventListener()
  }
}
</script>

<style lang="scss" scoped>
.home {
  padding-top: 60px;
  padding-bottom: 60px;
}
.price {
  font-family: Avenir-Heavy, PingFang SC, Helvetica Neue, Arial,
  sans-serif;
}

.list {
  width: 365px;
  margin: 0 auto 30px;
  background: #fafafa;
  box-shadow: #f4f4f4 1px 1px 1px 1px;
  border-radius: 10px;

  .item {
    box-sizing: border-box;
    width: 340px;
    margin: 0 auto 30px;
    border-radius: 15px;
    padding-bottom: 5px;

    .item-description {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 340px;
      margin: 0 auto 20px;
      border-radius: 15px;
    }
  }

  .title {
    font-size: 20px;
    padding: 10px 0;
    font-weight: 500;
    color: #333;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
  }
}

.item__info {
  display: flex;
  flex-direction: column;
  line-height: 1;
  width: 250px;
}

.item__title {
  font-size: 16px;
  line-height: 1;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  color: #2d4b5f;
}

.item__image {
  position: relative;

  img {
    width: 340px;
    height: 100%;
  }
}

.item__image-swiper {
  width: 340px;
  height: 250px;
  margin: 0 auto;
  border-radius: 15px;
}

.item__price {
  .item__price-discount {
    font-size: 20px;
    padding-right: 10px;
    color: #ff5741;
  }

  .item__price-origin {
    font-size: 12px;
    color: #ccc;
    text-decoration: line-through;
  }
}

.getmore-btn {
  position: absolute !important;
  right: 20px;
  bottom: 10px;
}
</style>