/**
 * 调用方式参考BaseRank
 * @param callback: // 触发条件后的回调函数
 * @param bottomDis: // 非必传，默认300，触发滚动的安全距离
 * @param useRefresh: // 非必传，默认false，是否使用顶部下拉刷新
 * @param obj: // 非必传，默认window，滚动盒子的节点对象（设置overflow: auto的对象）
 */
export default function (
	callback,
	bottomDis = 300,
	useRefresh = false,
	obj = null, // 滚动盒子的节点对象
) {
	let flag = false;
	const cb = () => {
		let scrollTop = window.pageYOffset;
		let winHeight = window.innerHeight;
		let docScrollHeight = document.documentElement.scrollHeight;
		if (obj) {
			scrollTop = obj.scrollTop;
			winHeight = obj.offsetHeight;
			docScrollHeight = obj.scrollHeight;
		}
		if (docScrollHeight - winHeight <= scrollTop + bottomDis) {
			callback();
		}

		if (useRefresh) {
			// 解决安卓下 fresh 动画影响向上拉的情况
			if (scrollTop > 10 && !flag) {
				// 向下滚动，并且没有禁止下拉刷新时
				// console.log("被禁止了");
				flag = true;
			}
			if (scrollTop <= 5 && flag) {
				// 滚动条距离顶部<5px，并且没有下拉刷新时
				// console.log("解除禁止");
				flag = false;
			}
		}
	};
	if (obj) {
		obj.addEventListener("scroll", cb);
	} else {
		window.addEventListener("scroll", cb);
	}

	return () => {
		if (obj) {
			obj.removeEventListener("scroll", cb);
		} else {
			window.removeEventListener("scroll", cb);
		}
	};
}
